import React, { Component } from "react";
import Public from "./public.js";
import Private from "./private";

export default class AppLayout extends Component {
  componentDidMount() {
    document.title = "Reaam :: Tracking";
  }

  render() {
    const queryParams = new URLSearchParams(window.location.search);
    const token = queryParams.get("token");
    const type = queryParams.get("type");

    return token === null ? (
      <Public {...this.props} />
    ) : (
      <Private {...this.props} {...{ type, token }} />
    );
  }
}
