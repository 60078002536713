import React from "react";
import { Flex, VStack, Heading } from "native-base";
import GoogleMpasCard from "./NewGoogleMap";
import { useTranslation } from "react-i18next";
const CourseEnded = () => {
  const { t } = useTranslation("Private");
  return (
    <VStack h="100vh" w="100vw ">
      <Flex flex={1} h="100%" w="100%" minW="container">
        <Heading
          fontFamily="Lato"
          fontWeight="700"
          textAlign="center"
          fontSize="18px"
          color="#032A47"
        >
          {t("OnGoingPage.ride_course_end")}
        </Heading>
        <GoogleMpasCard />
      </Flex>
    </VStack>
  );
};

export default CourseEnded;
