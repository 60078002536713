import AjusterVosDates from "composants/AjusterVosDates";
import ReservationsFuture from "composants/ReservationsFuture";
import React from "react";
import { BrowserRouter, Routes, Route, Redirect } from "react-router-dom";
import Organiser from "./Organiser";
import Suivres from "./Suivres";
import CourseEnded from "composants/CourseEnded";

const Rendu = (props) => {
  const serie =
    props.type === "serie" ? (
      <>
        <Route path="/organiser" element={<Organiser {...props} />} />
        <Route path="/ajusterVosDates" element={<AjusterVosDates />} />
        <Route path="/reservationsFuture" element={<ReservationsFuture />} />
      </>
    ) : null;
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/suivre" element={<Suivres {...props} />} />
        {serie}
        <Route path="/*" element={<Suivres {...props} />} />
        <Route path="/courseEnd" element={<CourseEnded />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Rendu;
