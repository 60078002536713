import { put, takeLatest } from "redux-saga/effects";
import TrackingActions, { TrackingTypes } from "reducers/tracking";

import _ from "lodash";

const graphQLClient = () => {
  const { graphQLClient } = require("../graphql");
  return graphQLClient;
};

function* patientLoadRideAsyncRequest({ token }) {
  const query = `
    {
        PatientLoadRideForPatient(token: "${token}")
    }
`;
  try {
    const { PatientLoadRideForPatient } = yield graphQLClient().request(query);

    yield put(
      TrackingActions.patientLoadRideRequestSuccess(PatientLoadRideForPatient)
    );
  } catch (error) {
    // const { message = "" } = error.response.errors[0];
    const message = "error";
    yield put(TrackingActions.patientLoadRideRequestFailure(message));
  }
}

/* ------------- Connect Types To Sagas ------------- */
export default [
  takeLatest(
    TrackingTypes.PATIENT_LOAD_RIDE_REQUEST,
    patientLoadRideAsyncRequest
  ),
];
