import { Box, Heading, Center, Flex, Spacer } from "native-base";

export default function (props) {
  return (
    <Box flex="1">
      <Center>
        <Heading textAlign="center" size="3xl">
          404 page not found
        </Heading>
      </Center>
    </Box>
  );
}
