import { put, takeLatest } from "redux-saga/effects";

import _ from "lodash";
import SerieActions, { SerieTypes } from "reducers/serie";

const graphQLClient = () => {
  const { graphQLClient } = require("../graphql");
  return graphQLClient;
};

function* patientLoadSerieAsyncRequest({ token }) {
  const query = `
    {
        PatientLoadSerieForPatient(token: "${token}")
    }
`;
  try {
    const { PatientLoadSerieForPatient } = yield graphQLClient().request(query);

    yield put(
      SerieActions.patientLoadSerieRequestSuccess(PatientLoadSerieForPatient)
    );
  } catch (error) {
    // const { message = "" } = error.response.errors[0];
    const message = "error";
    yield put(SerieActions.patientLoadSerieRequestFailure(message));
  }
}

/* ------------- Connect Types To Sagas ------------- */
export default [
  takeLatest(
    SerieTypes.PATIENT_LOAD_SERIE_REQUEST,
    patientLoadSerieAsyncRequest
  ),
];
