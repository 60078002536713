import {
  Box,
  VStack,
  Text,
  Heading,
  HStack,
  Button,
  Center,
  Input,
} from "native-base";
import React, { useEffect, useRef, useState } from "react";
import { DayPicker, Row, RowProps } from "react-day-picker";

import "react-day-picker/dist/style.css";
import { useNavigate } from "react-router-dom";
import ListeBesoin from "./AjusterVosDates";
import TimePicker from "./TimePicker";
import date_fr from "date-fns/locale/fr";
import { fontFamily } from "styled-system";
import SerieActions from "../reducers/serie";
import { useDispatch, useSelector } from "react-redux";
const calandarInput = `
  .rdp-day_selected:not([disabled]), .rdp-day_selected:focus:not([disabled]), .rdp-day_selected:active:not([disabled]), .rdp-day_selected:hover:not([disabled]){
    background-color: #9273FF;
      font-weight: bold;
      border: 2px solid white;
      color: white;
  }
  .my-today {
    font-weight: bold;
    font-size: 130%;
  }
  .rdp-button:focus:not([disabled]), .rdp-button:active:not([disabled]) {
    color: white;
    border: 0px;
  }
`;
const buttonINput = `
.react-time-picker__wrapper{
  width:70px;
  height:30px;
  border-color:#999C9F;
  border-radius:8px;
}
.react-time-picker__inputGroup{
  font-family:Lato;
  font-weight:400;
  font-size:12px;
  padding-left:12px;
  color:#8A8A8D;
}
`;
const cssRed = `
.react-time-picker__wrapper{
  width:70px;
  height:30px;
  border-color:#FF1700;
  border-radius:8px;
}
`;
const calandarColor = `
.css-view-1dbjc4n{
  border-color:#FF1700;
  border-radius:8px;
}

`;

const OrganisationSeries = ({ token = "" }) => {
  const serie = useSelector((state) => state.serie);
  const dispatch = useDispatch();
  const [startTime, setStartTime] = useState("08:30");
  const [endTime, setEndTime] = useState("10:00");
  const [numeroSecu, setNumeroSecu] = useState("");
  const inputRef = useRef(null);
  const timeCheckStart = /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/.test(startTime);
  const timeCheckEnd = /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/.test(endTime);
  const numberDates = serie.serie.nbDates;

  const getNumeroSecu = () => {
    setNumeroSecu(inputRef.current.value);
  };
  let navigation = useNavigate();
  let initialDays = [new Date()];

  let [days, setDays] = useState(initialDays);
  useEffect(() => {
    let arr = [];
    if (serie.serie.isCompleted) {
      for (const d of serie.serie.dates) {
        arr.push(new Date(d));
      }
    } else {
      for (const d of serie.dates) {
        arr.push(new Date(d));
      }
    }
    setDays([new Date()]);
  }, [serie.dates, serie.serie.dates]);

  const handleClick = () => {
    navigation("/ajusterVosDates", {
      state: {
        days: days,
        startTime: startTime,
        endTime: endTime,
      },
    });
  };

  const compareDates = days.length < numberDates || days.length > numberDates;
  useEffect(() => {
    dispatch(SerieActions.patientLoadSerieRequest(token));
  }, []);
  // console.log("serie de pavel",serie)

  return (
    <VStack>
      <style>{calandarInput}</style>
      <style>{buttonINput}</style>

      <Heading
        fontWeight="700"
        fontSize="14px"
        fontFamily="Lato"
        textAlign="center"
        color="#032A47"
        paddingTop="20px"
      >
        Renseigner les dates de vos soins
      </Heading>
      <Heading
        fontWeight="700"
        fontSize="12px"
        fontFamily="Lato"
        textAlign="center"
        color="#032A47"
        paddingTop="10px"
        paddingBottom="16px"
      >
        {" "}
        Vous avez{" "}
        <Text fontWeight="900" color="#FF1700">
          {numberDates} soins
        </Text>{" "}
        prescrit par votre medecin
      </Heading>

      <Center>
        <HStack>
          <Text
            paddingRight="7px"
            paddingLeft="7px"
            fontFamily="Lato"
            fontWeight="400"
            fontSize="12px"
            color="#032A47"
            paddingTop="3px"
          >
            Heure de début
          </Text>
          <Box marginRight="20px">
            <TimePicker
              disabled={serie.serie.isCompleted}
              time={startTime}
              onChange={setStartTime}
              isStart={true}
            />{" "}
          </Box>
          <Text
            paddingRight="7px"
            fontFamily="Lato"
            fontWeight="400"
            fontSize="12px"
            color="#032A47"
            paddingTop="3px"
          >
            Heure de fin{" "}
          </Text>
          <Box marginRight="20px">
            <TimePicker
              disabled={serie.serie.isCompleted}
              time={endTime}
              isStart={false}
              onChange={setEndTime}
            />{" "}
          </Box>
        </HStack>

        <Box
          borderWidth={1}
          borderColor="#8A8A8D"
          borderRadius="13px"
          marginBottom="3px"
        >
          <DayPicker
            mode="multiple"
            min={1}
            locale={date_fr}
            selected={days}
            onSelect={setDays}
            styles={{
              caption: { color: "#9273FF" },
              head: {
                color: "#E5E5E5",
                fontFamily: "SF Pro Text",
                fontWeight: "600",
                fontsize: "13px",
              },
            }}
            disabled={serie.serie.isCompleted}
          />
        </Box>
        <Box marginBottom="27px">
          {!(timeCheckStart && timeCheckEnd) ? (
            <Box>
              <style>{cssRed}</style>
              <Text
                fontFamily="Lato"
                fontWeight="400"
                fontStyle="italic"
                fontSize="12px"
                color="#FF1700"
              >
                Vous devez renseignier toutes les informations pour valider
                votre série.{" "}
              </Text>
            </Box>
          ) : (
            ""
          )}
        </Box>
        <Box marginBottom="10px">
          {compareDates && (
            <Box>
              <style>{calandarColor}</style>

              <Text
                fontFamily="Lato"
                marginTop="-20px"
                fontWeight="400"
                fontStyle="italic"
                fontSize="12px"
                paddingLeft="130px"
                width="80%"
                numberOfLines={2}
                color="#FF1700"
              >
                Vous avez{" "}
                <Text fontWeight="900" color="#FF1700">
                  {numberDates} dates
                </Text>{" "}
                à sélectionner d’après la demande du services, poursuivre votre
                demande.
              </Text>
            </Box>
          )}
        </Box>

        <Text
          paddingRight="40px"
          paddingBottom="3px"
          fontFamily="Lato"
          fontWeight="400"
          fontSize="12px"
        >
          Renseignez votre numéro de sécurité sociale (facultatif){" "}
        </Text>
        <Input
          ref={inputRef}
          onChangeText={getNumeroSecu}
          value={numeroSecu}
          _focus={{
            borderColor: "#999C9F",
            bg: "white",
          }}
          borderColor="#999C9F"
          placeholder="Numéro de sécurité sociale :"
          placeholderTextColor="#8A8A8D"
          fontFamily="Lato"
          fontWeight="400"
          fontsize="12px"
          width="343px"
          height="40px"
          paddingLeft="10px"
          borderRadius="8px"
          borderWidth={1}
          marginBottom="17px"
        />

        <Button
          bg="#9273FF"
          _hover={{
            backgroundColor: "#9273FF",
          }}
          _text={{
            color: "white",
            fontFamily: "Lato",
            fontWeight: "900",
            fontSize: "12px",
            textTransform: "uppercase",
          }}
          _focus={{
            backgroundColor: "#9273FF",
          }}
          padding="8px, 22px, 8px, 22px"
          borderRadius="50px"
          width="340px"
          height="30px"
          marginBottom="10px"
          onPress={
            !(timeCheckStart && timeCheckEnd) || compareDates ? "" : handleClick
          }
        >
          SUIVANT
        </Button>
      </Center>
    </VStack>
  );
};

export default OrganisationSeries;
