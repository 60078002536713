import Navigation from "composants/Navigation";
import OrganisationSeries from "composants/OrganisationSeries";
import { Box } from "native-base";
import React from "react";

const Organiser = (props) => {
  return (
    <Box>
      <Navigation />
      <OrganisationSeries {...props} />
    </Box>
  );
};

export default Organiser;
