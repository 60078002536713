import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  serieResetState: null,
  serieRequest: null,
  serieSetDates: ["dates"],
  // serieSetStart: ["start"],
  // serieSetEnd: ["end"],
  patientLoadSerieRequest: ["token"],
  patientLoadSerieRequestSuccess: ["serie"],
  patientLoadSerieRequestFailure: ["error"],
});

export const SerieTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({
  serie: {},
  dates: [],
  // start: "",
  // end: "",
  action: {
    loading: false,
    error: "",
  },
});

/* ------------- Reducers ------------- */

export const serieRequest = (state, action) => {
  return state;
};

export const serieResetState = (state, action) => {
  return INITIAL_STATE;
};

// Load
export const patientLoadSerieRequest = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], true);
  return Immutable.setIn(nextSate, ["action", "error"], "");
};
export const serieSetDates = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], false);
  return Immutable.setIn(nextSate, ["dates"], action.dates);
};
// export const serieSetStart = (state, action) => {
//   const nextSate = Immutable.setIn(state, ["start"], false);
//   return Immutable.setIn(nextSate, ["start"], action.start);
// };
// export const serieSetEnd = (state, action) => {
//   const nextSate = Immutable.setIn(state, ["end"], false);
//   return Immutable.setIn(nextSate, ["end"], action.end);
// };

export const patientLoadSerieRequestFailure = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], false);
  return Immutable.setIn(nextSate, ["action", "error"], action.error);
};

export const patientLoadSerieRequestSuccess = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], false);
  return Immutable.setIn(nextSate, ["serie"], action.serie);
};

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.SERIE_REQUEST]: serieRequest,
  [Types.SERIE_RESET_STATE]: serieResetState,
  // [Types.SERIE_SET_END]:serieSetEnd,
  // [Types.SERIE_SET_START]:serieSetStart,
  [Types.SERIE_SET_DATES]: serieSetDates,
  [Types.PATIENT_LOAD_SERIE_REQUEST]: patientLoadSerieRequest,
  [Types.PATIENT_LOAD_SERIE_REQUEST_SUCCESS]: patientLoadSerieRequestSuccess,
  [Types.PATIENT_LOAD_SERIE_REQUEST_FAILURE]: patientLoadSerieRequestFailure,
});
