import { Box } from "native-base";
import React from "react";
import ViewAppWithMap from "./ViewAppWithMap";

const Suivres = (props) => {
  return (
    <Box>
      <ViewAppWithMap {...props} />
    </Box>
  );
};

export default Suivres;
