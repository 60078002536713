import React, { Component } from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { Store, Persistor } from "store";
import moment from "moment";
import "moment/locale/fr";
import { I18nextProvider } from "react-i18next";
import i18n from "locales";
import AppLayout from "layouts";

moment.locale("fr");

export default class Setup extends Component {
  render() {
    return (
      <Provider store={Store}>
        <PersistGate persistor={Persistor}>
          <I18nextProvider i18n={i18n}>
            <AppLayout {...this.props} />
          </I18nextProvider>
        </PersistGate>
      </Provider>
    );
  }
}
