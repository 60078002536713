import React from "react";
import { HStack, Text, VStack } from "native-base";
import TimePicker from "react-time-picker";

export default function ({
  disabled = false,
  isStart = null,
  time = "10:00",
  onChange = () => null,
}) {
  let clearIconProps = {};
  if (disabled) {
    clearIconProps = {
      clearIcon: null,
    };
  }
  return (
    <HStack space={1}>
      <TimePicker
        disabled={disabled}
        format={"HH:mm"}
        onChange={onChange}
        value={time}
        disableClock={true}
        clearIcon={null}
        // {...clearIconProps}
      />
    </HStack>
  );
}
