import React from "react";
import { Text, Box, Heading } from "native-base";
import Header from "composants/Header";
import ThumbClickIcon from "composants/ThumbClickIcon";
import { useTranslation } from "react-i18next";
import { ButtonBottom } from "composants/ButtonBottom";

const GiveOpinions = () => {
  const { t } = useTranslation("Private");
  return (
    <Box alignItems="center" bg="##00000040">
      {/* add header button */}
      <Header />
      <Heading
        color="#032A47"
        fontFamily="Lato"
        fontSize="16px"
        fontWeight="700"
        paddingBottom="4px"
      >
        Vous êtes arrivé!
      </Heading>

      <Text
        paddingLeft="28px"
        paddingRight="28px"
        fontFamily="Lato"
        fontWeight="400"
        fonytSize="12px"
        lineHeight="14.4px"
        color="#000000"
        marginBottom="30px"
        textAlign="center"
      >
        {t("OnGoingPage.ride_resaam_download")}
      </Text>
      {/* display the fifferents icon when user click on thub  */}
      <ThumbClickIcon />
      {/* add button */}

      <ButtonBottom />
    </Box>
  );
};

export default GiveOpinions;
