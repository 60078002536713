import React from "react";
import { Text, Box, Heading, VStack, flex } from "native-base";
import StatutCourse from "composants/StatutCourse";

const ViewAppWithMap = (props) => {
  return (
    <Box alignItems="center">
      <StatutCourse {...props} />
    </Box>
  );
};

export default ViewAppWithMap;
