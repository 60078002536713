import React, { Fragment, forwardRef } from "react";
import ActionSheet from "actionsheet-react";
import {
  Text,
  Box,
  Divider,
  Heading,
  VStack,
  Flex,
  HStack,
  SDivider,
  useDisclose,
  Center,
  Actionsheet,
  Button,
  Container,
} from "native-base";
import CompagnyIcon from "assets/img/Compagny-logo.svg";
import { useTranslation } from "react-i18next";
import { RIDE_STATUS } from "composants/ConstData";
import { useSelector } from "react-redux";
import AmbulanceProfil from "assets/img/AmbulanceProfil.svg";
import VslProfil from "assets/img/VSLProfil.svg";
import TaxiProfil from "assets/img/TaxiProfil.svg";
import AmublanceLogo from "assets/img/ambulance-logo.svg";

const DateParser = (chaine) => {
  let newDate = new Date(chaine).toLocaleTimeString("fr-FR", {
    hour: "numeric",
    minute: "numeric",
  });
  return newDate;
};

const Swipupe = forwardRef((props, ref) => {
  const { dureeTrajet } = useSelector((state) => state.tracking);
  const { t } = useTranslation("Private");
  // on asigne a ride l'objet Mock_Ride qui represente lapi contenant touute les informations
  const { ride } = useSelector((state) => state.tracking);
  // on verifie quel type de taxi on a ensuite selon le type on recupere les information dans la logique
  const typeRide =
    ride.rideType == "taxiRide"
      ? ride.taxi.vehicle !== null
      : ride.transporter.vehicle !== null;
  let vehicule = null;
  if (typeRide) {
    vehicule =
      ride.rideType == "taxiRide"
        ? ride.taxi.vehicle
        : ride.transporter.vehicle;
  }
  // le statut represente : le status de la course " ex Transport en cours ou Vehicule en chemin
  //  extrait dans le statut de lobjet ride"
  let status = ride.status;
  // on renvoie le bon statut en fonction du statut en cours
  if (status == RIDE_STATUS.onway || status == RIDE_STATUS.onway) {
    status = status;
  }
  let ImageVehicule = null;
  if (ride.vehicleCategory == "TAXI") {
    ImageVehicule = TaxiProfil;
  } else if (ride.vehicleCategory == "AMBULANCE") {
    ImageVehicule = AmbulanceProfil;
  } else {
    ImageVehicule = VslProfil;
  }
  //ici on verifie et recupere le nom de la compagny en fonction
  // du type soit cest campagne de type : taxi ou transporter
  let names = null;
  if (ride.rideType == "taxiRide") {
    names = ride.taxi.company == null ? "" : ride.taxi.company.name;
  } else if (ride.rideType == "transporterRide") {
    names =
      ride.transporter.company == null ? "" : ride.transporter.company.name;
  }

  if (ride.status == RIDE_STATUS.onway || ride.status == RIDE_STATUS.onboard) {
    return (
      <Fragment>
        <ActionSheet ref={ref}>
          <Box>
            <Box alignItems="center">
              <Heading
                size="md"
                width="46px"
                height="5px"
                bg="#C4C4C4"
                marginTop="16px"
                borderRadius="100px"
                marginBottom="16px"
                onPress={props.handleClose}
              ></Heading>
            </Box>
            <Heading
              color="#032A47"
              size="xl"
              fontWeight="900"
              fontFamily="Lato"
              fontSize="24px"
              textAlign="center"
              paddingBottom="16px"
              // lineHeight="28.8px"
            >
              {t(`RideStatus.${status}`)}
            </Heading>

            <Heading
              fontFamily="Lato"
              fontWeight="400"
              fontStyle="italic"
              color="#8A8A8D"
              fontSize="14px"
              textAlign="center"
              paddingBottom="29px"
            >
              {ride.status == RIDE_STATUS.onway
                ? t("OnGoingPage.ride_pickup_hour")
                : t("OnGoingPage.ride_pickup_to_file")}
              <Text color="#032A47">
                {DateParser(
                  dureeTrajet
                    ? new Date(new Date().getTime() + dureeTrajet * 1000)
                    : ride.datetime
                )}
              </Text>{" "}
            </Heading>
            <Divider
              marginLeft="40px"
              width="329.5px"
              height="2px"
              orientation="horizontale"
              borderColor="#E5E5E5"
            />
            <HStack alignItems="center">
              <Box paddingLeft="34px">
                <img
                  src={
                    ride.vehicleCategory == "AMBULANCE"
                      ? AmublanceLogo
                      : CompagnyIcon
                  }
                  width={56}
                  height={56}
                />
              </Box>
              <Text
                paddingLeft="11px"
                fontFamily="Lato"
                fontWeight="700"
                fontSize="14px"
                width="30%"
              >
                {names}
              </Text>
              <Box paddingLeft="20px" paddingRight="9px">
                <img src={ImageVehicule} width={57} height={95} />
              </Box>
              <VStack>
                <Heading
                  size="lg"
                  fontSize="14px"
                  color="#9273FF"
                  fontWeight="700"
                  fontFamily="Lato"
                >
                  {vehicule.plate}
                </Heading>
                <Text
                  fontFamily="Lato"
                  fontWeight="400"
                  Size="14px"
                  color="#032A47"
                >
                  {vehicule.make} {vehicule.model}
                </Text>
                <Text
                  color="#032A47"
                  fontWeight="400"
                  Size="12px"
                  fontFamily="Lato"
                >
                  {vehicule.color}
                </Text>
              </VStack>
            </HStack>
          </Box>
          <Divider
            marginLeft="40px"
            marginTop="12px"
            marginBottom="14px"
            width="329.5px"
            height="2px"
            orientation="horizontale"
            borderColor="#E5E5E5"
          />
          <HStack marginBottom="15px">
            <Box marginLeft="33px" justifyContent="space-between" w="180px">
              <Text
                fontFamily="Lato"
                fontWeight="400"
                fontStyle="italic"
                fontSize="12px"
                color="#8A8A8D"
                paddingBottom="4px"
              >
                {t("OnGoingPage.ride_pickup_title")}
              </Text>
              <Text
                fontFamily="Lato"
                fontWeight="400"
                fontSize="12px"
                color="#032A47"
                lineHeight="14.4px"
                paddingBottom="4px"
                numberOfLines={1.5}
              >
                {ride.pickup.address}
              </Text>

              <Text
                fontFamily="Lato"
                fontWeight="400"
                fontStyle="italic"
                fontSize="12px"
                color="#8A8A8D"
                paddingBottom="4px"
              >
                {t("OnGoingPage.ride_dropoff_title")}
              </Text>
              <Text
                fontFamily="Lato"
                fontWeight="400"
                fontSize="12px"
                color="#032A47"
                lineHeight="14.4px"
                numberOfLines={1.5}
              >
                {ride.dropoff.address}
              </Text>
            </Box>

            <VStack paddingLeft="50px" w="200px" paddingTop="40px">
              <Text
                fontFamily="Lato"
                fontWeight="400"
                fontStyle="italic"
                color="#8A8A8D"
                fontSize="12px"
              >
                {t("OnGoingPage.ride_number_title")}
              </Text>
              <Text
                fontFamily="Lato"
                fontWeight="700"
                fontSize="12px"
                color="#032A47"
              >
                {ride.rideNumber}
              </Text>
            </VStack>
          </HStack>
        </ActionSheet>
      </Fragment>
    );
  }
});

export default Swipupe;
