import { bg } from "date-fns/locale";
import { Box, Button, Center, HStack, Text } from "native-base";
import React from "react";
import { NavLink, Location, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { color } from "styled-system";

const Navigation = () => {
  const { t } = useTranslation("Private");
  const location = useLocation();
  const navigate = useNavigate();
  const navigateOrganiser = () => {
    navigate("/organiser/");
  };
  const navigateSuivre = () => {
    navigate("/suivre/");
  };

  return (
    <Box zIndex={2} marginTop="5px">
      <Center>
        <HStack>
          <Button
            bg={location.pathname == "/suivre/" ? "#f0f0f0" : "#032A47"}
            onPress={navigateOrganiser}
            // {location.pathname=="/suivre" ? bg="#032A47" :bg="#f0f0f0" }
            _hover={{
              background: "#032A47",
              _text: {
                color: "#f0f0f0",
              },
            }}
            _text={{
              color: location.pathname == "/suivre/" ? "#032A47" : "white",
              fontFamily: "Lato",
              fontWeight: "700",
              fontSize: "14px",
              textDecoration: "none",
            }}
            width="105px"
            height="23px"
            padding="8px, 20px, 8px, 20px"
            borderRadius="4px"
          >
            {t("OnGoingPage.ride_navigate_title1")}
          </Button>

          <Button
            bg={location.pathname == "/organiser/" ? "#f0f0f0" : "#032A47"}
            onPress={navigateSuivre}
            _hover={{
              background: "#032A47",
              _text: {
                color: "#f0f0f0",
              },
            }}
            _text={{
              color: location.pathname == "/organiser/" ? "#032A47" : "#f0f0f0",
              fontFamily: "Lato",
              fontWeight: "700",
              fontSize: "14px",
              textDecoration: "none",
            }}
            width="108.18px"
            height="23px"
            padding="8px, 20px, 8px, 20px"
            borderRadius="4px"
          >
            {t("OnGoingPage.ride_navigate_title2")}
          </Button>
        </HStack>
      </Center>
    </Box>
  );
};

export default Navigation;
