export const RIDE_STATUS = {
  incoming: "incoming", // le service recharche un vehicule
  pending: "pending", //
  onway: "onway", // le transporteur en route pour recuperer le patient
  waiting: "waiting", // le patient attend  l'arriver du transporteur pour sa prise en chargre
  onboard: "onboard", // prise en charge du patient
  arrived: "arrived", // le patient est arrivé a destination
  completed: "completed", //  transport du patient terminer
  canceled: "canceled", // annuler par le transporteur, ou le service hospitalier
};
