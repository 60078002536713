import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  trackingRequest: null,
  trackingResetState: [],
  patientDureeTrajet: ["dureeTrajet"],
  patientLoadRideRequest: ["token"],
  patientLoadRideRequestSuccess: ["ride"],
  patientLoadRideRequestFailure: ["error"],
});

export const TrackingTypes = Types;

export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({
  ride: {},
  dureeTrajet: null,

  action: {
    loading: false,
    error: "",
  },
});
/* ------------- Reducers ------------- */

export const trackingRequest = (state, action) => {
  return state;
};

export const trackingResetState = (state, action) => {
  return INITIAL_STATE;
};

export const patientLoadRideRequest = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], true);
  return Immutable.setIn(nextSate, ["action", "error"], "");
};
export const patientDureeTrajet = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], false);
  return Immutable.setIn(nextSate, ["dureeTrajet"], action.dureeTrajet);
};

export const patientLoadRideRequestFailure = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], false);
  return Immutable.setIn(nextSate, ["action", "error"], action.error);
};

export const patientLoadRideRequestSuccess = (state, action) => {
  let nextSate = Immutable.setIn(state, ["action", "loading"], false);
  return Immutable.setIn(nextSate, ["ride"], action.ride);
};

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.TRACKING_REQUEST]: trackingRequest,
  [Types.TRACKING_RESET_STATE]: trackingResetState,
  //patientDureeTrajet
  [Types.PATIENT_DUREE_TRAJET]: patientDureeTrajet,
  [Types.PATIENT_LOAD_RIDE_REQUEST]: patientLoadRideRequest,

  [Types.PATIENT_LOAD_RIDE_REQUEST_FAILURE]: patientLoadRideRequestFailure,
  [Types.PATIENT_LOAD_RIDE_REQUEST_SUCCESS]: patientLoadRideRequestSuccess,
});
