import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Text,
  VStack,
  Heading,
  Center,
  Input,
  Button,
  HStack,
} from "native-base";
import Navigation from "./Navigation";
import InputDate from "./InputDate";
import TimePicker from "./TimePicker";
import { useNavigate, useLocation } from "react-router-dom";
import SerieActions from "../reducers/serie";
import { useDispatch, useSelector } from "react-redux";
const css1 = `
.react-time-picker__wrapper{
  width:80px;
  height:40px;
  border-color:#999C9F;
  border-radius:8px;
  margin-right:20px;
  margin-left:20px;
  color:#032A47;
}
.react-time-picker__inputGroup{
  font-family:Lato;
  font-weight:400;
  font-size:12px;
  padding-left:12px;
  
}
`;

const AjusterVosDates = () => {
  const serie = useSelector((state) => state.serie);
  const dispatch = useDispatch();
  const [dates, setDates] = useState([]);
  const { state } = useLocation();
  const [day, setDays] = useState(state.days);
  const [timeStart, setTimeStart] = useState(state.startTime);
  const [timeEnd, setTimeEnd] = useState(state.endTime);

  let navigation = useNavigate();

  // console.log("tranferts de donnees :",state);
  function PrexReservationFuture() {
    navigation("/reservationsFuture");
    //{
    //   state: {
    //     dates,
    //   },
    // });
  }

  useEffect(
    () => {
      let arr = [];

      for (const d of day) {
        arr.push({ date: d, start: timeStart, end: timeEnd });
      }

      setDates(arr);
    },
    day,
    timeStart,
    timeEnd
  );

  useEffect(() => {
    dispatch(SerieActions.serieSetDates(dates));
  }, [dates]);
  function changeDate(date, type, value) {
    setDates((current) =>
      current.map((c) => {
        if (c.date == date) {
          return { ...c, [type]: value };
        }
        return c;
      })
    );
  }
  // console.log("ajuster vos dates", dates);

  return (
    <VStack>
      <style>{css1}</style>
      <Navigation />
      <Heading
        fontWeight="700"
        fontSize="14px"
        fontFamily="Lato"
        textAlign="center"
        color="#032A47"
        paddingTop="20px"
        paddingBottom="21px"
      >
        Ajuster vos dates si besoin
      </Heading>

      <Center>
        <HStack marginBottom="10px">
          <Text
            position="absolute"
            top="0px"
            right="185px"
            color="#032A47"
            fontFamily="Lato"
            fontWeight="900"
            fontSize="12px"
          >
            Date
          </Text>
          <Text
            paddingRight="30px"
            fontFamily="Lato"
            fontWeight="900"
            fontSize="12px"
            color="#032A47"
          >
            Début
          </Text>
          <Text
            position="absolute"
            top="0px"
            left="120px"
            fontFamily="Lato"
            fontWeight="900"
            fontSize="12px"
            color="#032A47"
          >
            Fin{" "}
          </Text>
        </HStack>
        {day?.map((date, key) => {
          return (
            <HStack marginBottom={2} key={key} position="relative">
              <InputDate
                date={date}
                setDate={(value) => {
                  changeDate(date, "date", value);
                }}
              />
              <TimePicker
                disabled={false}
                time={timeStart}
                onChange={(value) => changeDate(date, "start", value)}
              />
              <TimePicker
                disabled={false}
                time={timeEnd}
                onChange={(value) => changeDate(date, "end", value)}
              />
            </HStack>
          );
        })}
        <Text
          paddingRight="40px"
          paddingTop="25px"
          paddingBottom="3px"
          fontFamily="Lato"
          fontWeight="400"
          fontSize="12px"
        >
          Renseignez votre numéro de sécurité sociale (facultatif){" "}
        </Text>
        <Input
          _focus={{
            borderColor: "#999C9F",
            bg: "white",
          }}
          borderColor="#999C9F"
          placeholder="Numéro de sécurité sociale :"
          placeholderTextColor="#8A8A8D"
          fontFamily="Lato"
          fontWeight="400"
          fontsize="12px"
          width="343px"
          height="40px"
          paddingLeft="10px"
          borderRadius="8px"
          borderWidth={1}
          marginBottom="17px"
        />
        <Button
          bg="#9273FF"
          _hover={{
            backgroundColor: "#9273FF",
          }}
          _text={{
            color: "white",
            fontFamily: "Lato",
            fontWeight: "900",
            fontSize: "12px",
            textTransform: "uppercase",
          }}
          _focus={{
            backgroundColor: "#9273FF",
          }}
          padding="8px, 22px, 8px, 22px"
          borderRadius="50px"
          width="340px"
          height="34px"
          marginBottom="10px"
          onPress={PrexReservationFuture}
        >
          Valider
        </Button>
      </Center>
    </VStack>
  );
};

export default AjusterVosDates;
