import React, { useRef, useState } from "react";
import { format, isValid, parse } from "date-fns";
import { DayPicker } from "react-day-picker";
import date_fr from "date-fns/locale/fr";
import "react-day-picker/dist/style.css";
import { Box, Center, CloseIcon, HStack, Modal, Pressable } from "native-base";
import moment from "moment";
import ImageCalendar from "assets/img/ImageCalendar.svg";

const css = `
  .rdp-day_selected:not([disabled]), .rdp-day_selected:focus:not([disabled]), .rdp-day_selected:active:not([disabled]), .rdp-day_selected:hover:not([disabled]){
    background-color: #9273FF;
      font-weight: bold;
      border: 2px solid white;
      color: white;
  }
  .my-today { 
    font-weight: bold;
    font-size: 130%;
  }
  .rdp-button:focus:not([disabled]), .rdp-button:active:not([disabled]) {
    color: white;
    border: 0px;
  }
`;
const bord = `
placeholder{
  font-family:Lato;
  font-weight:400;
  font-size:12px
}
.button-reset{
  border:none;
  background:none;
}
.input-reset {
  width:100px;
  height:30px;
  border-radius:8px;
    border: 2px solid #999C9F;
  color:#032A47;
  padding-left:10px;

}

`;

export default function InputDate({
  date = new Date(),
  setDate = () => null,
  disabled = false,
}) {
  const DateParser = (chaine) => {
    let newDate = new Date(chaine).toLocaleDateString("fr-FR", {
      day: "numeric",
      month: "long",
      year: "numeric",
    });
    return newDate;
  };
  const [selected, setSelected] = useState();
  const [inputValue, setInputValue] = useState(DateParser(date));
  const [isPopperOpen, setIsPopperOpen] = useState(false);

  const popperRef = useRef(null);
  const buttonRef = useRef(null);

  const closePopper = () => {
    setIsPopperOpen(false);
    buttonRef?.current?.focus();
  };

  const handleInputChange = (e) => {
    const currentInputDate = e.currentTarget.value;
    setInputValue(currentInputDate);
    try {
      const currentDate = moment(currentInputDate, "DD-MM-YYYY");
      if (currentDate.isValid()) {
        setDate(new Date(currentDate));
        setSelected(new Date(new Date(currentDate)));
      }
    } catch (error) {
      setSelected(undefined);
    }
  };

  const handleButtonClick = () => {
    setIsPopperOpen(true);
  };

  const handleDaySelect = (date) => {
    setSelected(date);
    if (date) {
      setDate(date);
      setInputValue(format(date, "dd-MM-y"));
      closePopper();
    } else {
      setInputValue("");
    }
  };

  return (
    <div>
      <style>{css}</style>
      <style>{bord}</style>

      <div ref={popperRef}>
        <HStack>
          <Center marginRight="4px">
            <input
              type="text"
              style={{ width: "80px" }}
              placeholder={format(date, "dd-MM-y")}
              value={inputValue}
              onChange={handleInputChange}
              className="input-reset pa2 ma2 bg-white black ba"
            />
          </Center>

          <button
            ref={buttonRef}
            type="button"
            className="pa2 bg-white button-reset ba"
            aria-label="Pick a date"
            onClick={handleButtonClick}
          >
            <span role="img" aria-label="calendar icon">
              <img src={ImageCalendar} />
            </span>
          </button>
        </HStack>
      </div>

      <Modal
        isOpen={isPopperOpen}
        onClose={closePopper}
        flex={1}
        top={0}
        bottom={0}
      >
        <Center bg="white" rounded={8} alignItems="end">
          <Box pt={4} pr={4}>
            <Pressable onPress={closePopper} size={6}>
              <CloseIcon />
            </Pressable>
          </Box>
          <DayPicker
            initialFocus={isPopperOpen}
            mode="single"
            locale={date_fr}
            defaultMonth={selected}
            selected={date}
            onSelect={handleDaySelect}
            disabled={disabled}
            styles={{
              caption: { color: "#9273FF" },
              head: {
                color: "#E5E5E5",
                fontFamily: "Lato",
                fontWeight: "600",
                fontsize: "13px",
              },
            }}
          />
        </Center>
      </Modal>
    </div>
  );
}
