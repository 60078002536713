import { Box, Center, Heading, HStack, Text } from "native-base";
import React, { useEffect, useState } from "react";
import Navigation from "./Navigation";
import logoBackup from "assets/img/LogoBackup.svg";
import { useNavigate, useLocation } from "react-router-dom";
import InputDate from "./InputDate";
import TimePicker from "./TimePicker";
import { useDispatch, useSelector } from "react-redux";
const css = `
.react-time-picker__wrapper{
  width:70px;
  height:30px;
  border-color:#8A8A8D;
  border-radius:8px;
  margin-right:20px;
  margin-left:20px
}
`;

const ReservationsFuture = () => {
  const { state } = useLocation();
  const serieDates = useSelector((state) => state.serie.dates);
  const [days, setDays] = useState(serieDates);
  // const [startTimes, setStartTimes] = useState(state.start);
  // const [endTimes, setEndTime] = useState(state.end);
  let navigation = useNavigate();
  function PrexBacOrganiser() {
    navigation("/organiser");
  }

  // useEffect(() => {
  //   setDays(state.dates);
  // }, [days]);
  return (
    <Box>
      <style>{css}</style>
      <Navigation />
      <Center>
        <HStack space={50}>
          <Heading
            fontWeight="700"
            fontSize="14px"
            fontFamily="Lato"
            paddingLeft="80px"
            textAlign="center"
            color="#032A47"
            paddingTop="20px"
            paddingBottom="30px"
          >
            Vos réservations futures
          </Heading>
          <Box paddingTop="11px" cursor="pointer">
            <img
              src={logoBackup}
              width="25px"
              height="35px"
              onClick={PrexBacOrganiser}
            />
          </Box>
        </HStack>
      </Center>
      <Center>
        <HStack marginBottom="10px">
          <Text
            position="absolute"
            top="0px"
            right="160px"
            color="#032A47"
            fontFamily="Lato"
            fontWeight="900"
            fontSize="12px"
          >
            Date
          </Text>
          <Text
            paddingRight="10px"
            fontFamily="Lato"
            fontWeight="900"
            fontSize="12px"
            color="#032A47"
          >
            Début
          </Text>
          <Text
            position="absolute"
            top="0px"
            left="110px"
            fontFamily="Lato"
            fontWeight="900"
            fontSize="12px"
            color="#032A47"
          >
            Fin{" "}
          </Text>
        </HStack>
        {days?.map((date, key) => {
          return (
            <HStack marginBottom={2} key={key} posistion="relative">
              <InputDate date={date.date} disabled={true} />
              <TimePicker disabled={true} time={date.start} isStart={true} />
              <TimePicker disabled={true} time={date.end} isStart={false} />
            </HStack>
          );
        })}
      </Center>
    </Box>
  );
};

export default ReservationsFuture;
