import React, { useState, useRef, useEffect } from "react";
import {
  Text,
  Box,
  Heading,
  VStack,
  Flex,
  HStack,
  Spinner,
  Divider,
  Center,
  useBreakpointValue,
  View,
} from "native-base";
import CompagnyIcon from "assets/img/Compagny-logo.svg";
import { RIDE_STATUS, MOCK_RIDE } from "composants/ConstData";
import NewGoogleMap from "composants/NewGoogleMap";
import Swipupe from "./Swipupe";
import GiveOpinions from "pages/GiveOpinions";
import { useTranslation } from "react-i18next";
import AmbulanceProfil from "assets/img/AmbulanceProfil.svg";
import VslProfil from "assets/img/VSLProfil.svg";
import TaxiProfil from "assets/img/TaxiProfil.svg";
import TrackingActions from "reducers/tracking";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import AmublanceLogo from "assets/img/ambulance-logo.svg";

import Public from "layouts/public";
import moment from "moment";

const StatutCourse = ({ token = "" }) => {
  const { ride } = useSelector((state) => state.tracking);
  const { dureeTrajet } = useSelector((state) => state.tracking);
  const dispatch = useDispatch();
  const refs = useRef();
  const { t } = useTranslation("Private");

  const handleOpen = () => {
    refs.current.open();
  };

  const handleClose = () => {
    refs.current.close();
  };

  const DateParser = (chaine) => {
    let newDate = new Date(chaine).toLocaleTimeString("fr-FR", {
      hour: "numeric",
      minute: "numeric",
    });
    return newDate;
  };

  useEffect(() => {
    dispatch(TrackingActions.patientLoadRideRequest(token));
    setInterval(() => {
      dispatch(TrackingActions.patientLoadRideRequest(token));
    }, 5000);
  }, []);

  if (_.isEmpty(ride)) {
    return null;
  }

  let vehicule = null;
  const isAssigned =
    (ride.taxi && ride.taxi.vehicle) ||
    (ride.transporter && ride.transporter.vehicle);

  if (isAssigned) {
    vehicule =
      ride.rideType == "taxiRide"
        ? ride.taxi.vehicle
        : ride.transporter.vehicle;
  }
  let status = ride.status;

  let ImageVehicule = null;
  if (ride.vehicleCategory == "TAXI") {
    ImageVehicule = TaxiProfil;
  } else if (ride.vehicleCategory == "AMBULANCE") {
    ImageVehicule = AmbulanceProfil;
  } else {
    ImageVehicule = VslProfil;
  }
  //ici on verifie et recupere le nom de la compagny en fonction
  // du type soit cest campagne de type : taxi ou transporter
  let names = null;
  if (ride.rideType == "taxiRide") {
    names = ride.taxi.company?.name || "";
  } else {
    names = ride.transporter.company?.name || "";
  }

  const RideStatus =
    ride.rideType == "samuTransporterRide" ? "SamuRideStatus" : "RideStatus";

  if (
    ride.status == RIDE_STATUS.incoming ||
    ride.status == RIDE_STATUS.pending
  ) {
    return (
      <VStack h="87vh" py={4} w="100vw" alignItems="center">
        <Flex flex={1} h="100%" w="100%" minW="container">
          <NewGoogleMap />
        </Flex>

        <Heading
          size="md"
          width="46px"
          height="5px"
          bg="#C4C4C4"
          marginTop="16px"
          borderRadius="100px"
          marginBottom="16px"
          onPress={handleOpen}
        ></Heading>
        <Heading
          color="#032A47"
          size="xl"
          fontWeight="900"
          fontFamily="Lato"
          fontSize="24px"
          textAlign="center"
          paddingBottom="16px"
        >
          {t(`${RideStatus}.${status}`)}
        </Heading>
        <HStack>
          <Box>
            <Text
              fontFamily="Lato"
              fontWeight="400"
              fontStyle="italic"
              color="#8A8A8D"
              fontSize="12px"
            >
              {t("OnGoingPage.ride_number_title")}
            </Text>
            <Text
              fontFamily="Lato"
              fontWeight={700}
              fontSize="14px"
              color="#032A47"
            >
              {ride.rideNumber}
            </Text>

            <Text
              fontFamily="Lato"
              fontWeight="400"
              fontStyle="italic"
              fontSize="12px"
              color="#8A8A8D"
              paddingBottom="4px"
            >
              {t("OnGoingPage.ride_pickup_title")}
            </Text>
            <Text
              fontFamily="Lato"
              fontWeight="400"
              Size="12px"
              color="#032A47"
              width={200}
              height={50}
              lineHeight="14.4px"
            >
              {ride.pickup.address}
            </Text>
          </Box>
          <Center>
            {!isAssigned && (
              <Box paddingLeft="62px" paddingBottom="70px" paddingRight="30px">
                {" "}
                <Spinner
                  accessibilityLabel="Loading posts"
                  color="#9273FF"
                  size={50}
                />
              </Box>
            )}

            {isAssigned && (
              <VStack
                space={4}
                alignItems="center"
                justifyContent="center"
                h="full"
              >
                <HStack space="11px">
                  <Box paddingLeft="7px">
                    <img
                      src={
                        ride.rideType == "samuTransporterRide"
                          ? AmublanceLogo
                          : CompagnyIcon
                      }
                      width={56}
                      height={56}
                    />
                  </Box>
                  <VStack>
                    <Text
                      fontFamily="Lato"
                      fontWeight="400"
                      color="#999C9F"
                      fontSize="12px"
                    >
                      Retrouvez
                    </Text>
                    <Text
                      fontFamily="Lato"
                      fontWeight="400"
                      color="#999C9F"
                      fontSize="12px"
                    >
                      l’entreprise
                    </Text>
                    <Text
                      fontFamily="Lato"
                      fontWeight="700"
                      color="#000000"
                      fontSize="12px"
                    >
                      {names}
                    </Text>
                  </VStack>
                </HStack>

                {vehicule && (
                  <HStack paddingLeft="60px">
                    <Center
                      position="absolute"
                      bottom="-15px"
                      right="90px"
                      top="-10px"
                    >
                      <img src={ImageVehicule} width={57} height={95} />
                    </Center>
                    <VStack>
                      <Heading
                        paddingRight="15px"
                        size="lg"
                        fontSize="14px"
                        color="#9273FF"
                        fontWeight="700"
                        fontFamily="Lato"
                      >
                        {vehicule.plate.toUpperCase()}
                      </Heading>
                      <Text
                        fontFamily="Lato"
                        fontWeight="400"
                        Size="14px"
                        color="#032A47"
                      >
                        {vehicule.make.toUpperCase()}{" "}
                        {vehicule.model.toUpperCase()}
                      </Text>
                      <Text
                        color="#032A47"
                        fontWeight="400"
                        Size="12px"
                        fontFamily="Lato"
                      >
                        {vehicule.color}
                      </Text>
                    </VStack>
                  </HStack>
                )}
              </VStack>
            )}
          </Center>
        </HStack>
      </VStack>
    );
  } else if (
    ride.status == RIDE_STATUS.onway ||
    ride.status == RIDE_STATUS.onboard
  ) {
    return (
      <VStack h="87vh" w="100vw " alignItems="center">
        <Flex flex={1} h="100%" w="100%" minW="container">
          <NewGoogleMap />
        </Flex>
        <Heading
          size="md"
          width="46px"
          height="5px"
          bg="#C4C4C4"
          marginTop="16px"
          borderRadius="100px"
          marginBottom="16px"
          onPress={handleOpen}
        ></Heading>
        <Heading
          color="#032A47"
          size="xl"
          fontWeight="900"
          fontFamily="Lato"
          fontSize="24px"
          textAlign="center"
          paddingBottom="16px"
        >
          {t(`${RideStatus}.${status}`)}
        </Heading>
        <Box>
          <Heading
            fontFamily="Lato"
            fontWeight="400"
            fontStyle="italic"
            color="#8A8A8D"
            fontSize="14px"
            textAlign="center"
            paddingBottom="29px"
          >
            {ride.status == RIDE_STATUS.onway
              ? `${t("OnGoingPage.ride_pickup_hour")} `
              : `${t("OnGoingPage.ride_pickup_to_file")} `}
            <Text color="#032A47">
              {DateParser(
                dureeTrajet
                  ? new Date(new Date().getTime() + dureeTrajet * 1000)
                  : ride.datetime
              )}
            </Text>{" "}
          </Heading>
          <Divider
            marginLeft="30px"
            width="329.5px"
            height="2px"
            orientation="horizontale"
            borderColor="#E5E5E5"
          />
          <HStack alignItems="center">
            <Box paddingLeft="33px">
              <img
                src={
                  ride.rideType == "samuTransporterRide"
                    ? AmublanceLogo
                    : CompagnyIcon
                }
                width={56}
                height={56}
              />
            </Box>
            <Text
              width="30%"
              paddingLeft="11px"
              fontFamily="Lato"
              fontWeight="700"
              fontSize="14px"
            >
              {names}
            </Text>
            <Box paddingLeft="30px" paddingRight="15px">
              <img src={ImageVehicule} width={57} height={95} />
            </Box>
            <VStack>
              <Heading
                size="lg"
                fontSize="14px"
                color="#9273FF"
                fontWeight="700"
                fontFamily="Lato"
              >
                {vehicule.plate.toUpperCase()}
              </Heading>
              <Text
                fontFamily="Lato"
                fontWeight="400"
                Size="14px"
                color="#032A47"
              >
                {vehicule.make.toUpperCase()} {vehicule.model.toUpperCase()}
              </Text>
              <Text
                color="#032A47"
                fontWeight="400"
                Size="12px"
                fontFamily="Lato"
              >
                {vehicule.color}
              </Text>
            </VStack>
          </HStack>
        </Box>
        {/* when the patient Swipeup we display more informations */}
        <Swipupe ref={refs} />
      </VStack>
    );
  } else if (ride.status == RIDE_STATUS.waiting) {
    return (
      <VStack h="87vh" py={4} w="100vw" alignItems="center">
        <Flex flex={1} h="100%" w="100%" minW="container">
          <NewGoogleMap />
        </Flex>
        <Heading
          size="md"
          width="46px"
          height="5px"
          bg="#C4C4C4"
          marginTop="16px"
          borderRadius="100px"
          marginBottom="13px"
        ></Heading>
        <Heading
          color="#032A47"
          size="xl"
          fontWeight="900"
          fontFamily="Lato"
          fontSize="24px"
          textAlign="center"
          paddingBottom="13px"
        >
          {t(`${RideStatus}.${status}`)}
        </Heading>
        <Box>
          <Heading
            fontFamily="Lato"
            fontWeight="400"
            fontStyle="italic"
            color="#8A8A8D"
            fontSize="14px"
            textAlign="center"
            paddingBottom="29px"
          >
            {t("OnGoingPage.ride_patient_wait")}
          </Heading>
          <Divider
            marginLeft="30px"
            width="329.5px"
            height="2px"
            orientation="horizontale"
            borderColor="#E5E5E5"
          />

          <HStack alignItems="center">
            <Box paddingLeft="33px">
              <img
                src={
                  ride.rideType == "samuTransporterRide"
                    ? AmublanceLogo
                    : CompagnyIcon
                }
                width={56}
                height={56}
              />
            </Box>
            <Text
              width="30%"
              paddingLeft="11px"
              fontFamily="Lato"
              fontWeight="700"
              fontSize="14px"
            >
              {names}
            </Text>
            <Box paddingLeft="30px" paddingRight="15px">
              <img src={ImageVehicule} width={57} height={95} />
            </Box>
            <VStack>
              <Heading
                size="lg"
                fontSize="14px"
                color="#9273FF"
                fontWeight="700"
                fontFamily="Lato"
              >
                {vehicule.plate.toUpperCase()}
              </Heading>
              <Text
                fontFamily="Lato"
                fontWeight="400"
                Size="14px"
                color="#032A47"
              >
                {vehicule.make.toUpperCase()} {vehicule.model.toUpperCase()}
              </Text>
              <Text
                color="#032A47"
                fontWeight="400"
                Size="12px"
                fontFamily="Lato"
              >
                {vehicule.color}
              </Text>
            </VStack>
          </HStack>
        </Box>
      </VStack>
    );
  } else if (
    ride.status == RIDE_STATUS.completed ||
    ride.status == RIDE_STATUS.canceled
  ) {
    return (
      <Box>
        {/* permet a l'utilissateur de donner son avis */}
        <GiveOpinions />
      </Box>
    );
  }
};

export default StatutCourse;
