import React, { useState } from "react";
import { Text, Button, HStack, Input, Box } from "native-base";
import thumbUPIcon from "assets/img/thumb-up.svg";
import thumbDwonIcon from "assets/img/thumb-down.svg";
import thumbUPColorIcon from "assets/img/thumb-up-color.svg";
import thumbDwonColorIcon from "assets/img/thumb-dwon-color.svg";

import thumbUpConduiteAgreableIcon from "assets/img/thumbup-conduite-agreable.svg";
import thumbUpPonctuelIcon from "assets/img/thumbup-ponctuel.svg";
import thumbUpAccueillantIcon from "assets/img/thumbup-accueillant.svg";
import thumbUpAssistanceIcon from "assets/img/thumbup-assistance.svg";

import thumbDwonConduiteDesagreableIcon from "assets/img/thumbDown-conduite-desagreable.svg";
import thumbDwonMauvaisAccueilIcon from "assets/img/thumbDown-mauvais-accueil.svg";
import thumbDwonComportementIcon from "assets/img/thumbDown-comportement.svg";
import thumbDwonPasDassistanceIcon from "assets/img/thumbDown-pas-dassistance.svg";

const ThumbClickIcon = () => {
  const [thumb, setThumb] = useState("");

  const changeThumbDwon = () => {
    setThumb("down");
  };
  const changeThumbUP = () => {
    setThumb("up");
  };
  return (
    <Box alignItems="center">
      <HStack
        //  marginLeft="80px"
        //  marginRight="80px"
        marginBottom="37px"
        // mx={{
        //   base: "auto",
        //   md: 0,
        // }}
        space="36px"
      >
        <img
          onClick={changeThumbUP}
          src={thumb === "up" ? thumbUPColorIcon : thumbUPIcon}
          width="90px"
          height="90px"
        />

        <img
          onClick={changeThumbDwon}
          src={thumb === "down" ? thumbDwonColorIcon : thumbDwonIcon}
          width="90px"
          height="90px"
        />
      </HStack>

      {/* on affiche les icons d'avis uniquement si l'usager a cliqué sur le pouce de satisfaction */}

      {thumb == "up" && (
        <HStack
          paddingBottom="30px"
          mx={{
            base: "auto",
            md: 0,
          }}
          space={1}
        >
          <img src={thumbUpConduiteAgreableIcon} width={80} height={83} />
          <img src={thumbUpPonctuelIcon} width={80} height={84} />
          <img src={thumbUpAccueillantIcon} width={80} height={84} />
          <img src={thumbUpAssistanceIcon} width={80} height={84} />
        </HStack>
      )}

      {/* on affiche les icons d'avis uniquement si l'usager a cliqué sur le pouce de mé&contentement */}
      {thumb == "down" && (
        <HStack
          paddingBottom="30px"
          mx={{
            base: "auto",
            md: 0,
          }}
          space={1}
        >
          <img src={thumbDwonConduiteDesagreableIcon} width={80} height={83} />
          <img src={thumbDwonMauvaisAccueilIcon} width={80} height={84} />
          <img src={thumbDwonComportementIcon} width={80} height={84} />
          <img src={thumbDwonPasDassistanceIcon} width={80} height={84} />
        </HStack>
      )}
      {/* if user click on thub we display the input */}
      {thumb && (
        <Input
          _focus={{
            borderColor: "#999C9F",
            bg: "white",
          }}
          borderColor="#999C9F"
          placeholder="Commentaire :"
          placeholderTextColor="#032A47"
          fontFamily="Lato"
          fontWeight="400"
          fontsize="12px"
          width="329px"
          height="40px"
          paddingLeft="24px"
          borderRadius="8px"
          borderWidth={1}
          marginBottom="12px"
        />
      )}
    </Box>
  );
};

export default ThumbClickIcon;
