import React, { useEffect } from "react";
import { Button } from "native-base";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import TrackingActions from "reducers/tracking";
import { useDispatch } from "react-redux";
export const ButtonBottom = () => {
  const dispatch = useDispatch();
  let navigation = useNavigate();
  const { t } = useTranslation("Private");
  const presButton = (e) => {
    navigation("/courseEnd");
  };
  useEffect(() => {
    dispatch(TrackingActions.patientLoadRideRequest());
  });
  return (
    <Button
      bg="white"
      borderRadius="50px"
      borderWidth={1}
      _hover={{
        backgroundColor: "white",
      }}
      _focus={{
        backgroundColor: "white",
      }}
      _text={{
        color: "black",
        fontWeight: "900",
        fontFamily: "Lato",
        fontSize: "12px",
        textTransform: "uppercase",
        padding: "10px 15px",
        fontFamily: "Lato",
      }}
      width="91px"
      height="30px"
      marginLeft="220px"
      borderColor="#032A47"
      onPress={presButton}
    >
      {t("OnGoingPage.ride_button_end")}
    </Button>
  );
};
