import React, { useState, useCallback, useEffect, useContext } from "react";
import MarkerIcon from "assets/img/MarKerIconComplet.svg";
import {
  useJsApiLoader,
  Marker,
  DirectionsRenderer,
  DirectionsService,
  GoogleMap,
} from "@react-google-maps/api";
import VehiculeIcon2 from "assets/img/VehiculeIcon2.svg";
import googleMapStyle from "assets/googleMapStyle";
import { MOCK_RIDE } from "composants/ConstData";
import LieuDarrivee from "assets/img/Lieu-darrivee.svg";
import LieuDePriseEnCharge from "assets/img/Lieu-de-prise-en-charge.svg";
import { Box, VStack } from "native-base";
import VslMap from "assets/img/VSLMap.svg";
import AmbulenceMap from "assets/img/AmbulanceMap.svg";
import TaxiMap from "assets/img/Taximaps.svg";
import { useSelector } from "react-redux";
import lieuDariveMaps from "assets/img/lieu-darriver-maps.svg";
import lieuPriseEnChargeMaps from "assets/img/lieu-de-prise-en-charge-maps.svg";
import DureeTrajetActions from "reducers/tracking";
import { useDispatch } from "react-redux";
import { now } from "moment";

const center = { lat: 48.862725, lng: 2.287592 };
const containerStyle = {
  width: "100%",
  height: "100%",
};
const libraries = ["places"];

const NewGoogleMap = () => {
  const dispatch = useDispatch();
  const { ride } = useSelector((state) => state.tracking);
  const dureeTrajet = useSelector((state) => state.tracking.dureeTrajet);
  const [map, setMap] = useState(null);
  const [directionResponse, setDirectionResponse] = useState(null);
  const [dureTrajet, setDureTrajet] = useState("");

  const google = window.google;
  const onUnmount = useCallback(function callback(map) {
    setMap(null);
  }, []);
  const onLoad = useCallback(function callback(map) {
    setMap(map);
  }, []);
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GMAPS_KEY,
    libraries,
  });

  let ImageVehicule = null;
  if (ride.vehicleCategory == "TAXI") {
    ImageVehicule = TaxiMap;
  } else if (ride.vehicleCategory == "AMBULANCE") {
    ImageVehicule = AmbulenceMap;
  } else {
    ImageVehicule = VslMap;
  }

  // on recupere les cordonnées du driver en fonction du type rideType
  // qui peut etre soit du type taxiRide ou du type transporterRide
  let coord = null;
  if (ride.rideType == "taxiRide") {
    coord =
      ride.taxi.driver == null
        ? ""
        : ride.taxi.drive.coords
        ? ride.taxi.drive.coords
        : "";
  } else {
    coord =
      ride.transporter.driver == null
        ? ""
        : ride.transporter.driver.coords
        ? ride.transporter.driver.coords
        : "";
  }
  // console.log("coordonnées",dureTrajet);
  const drawDirection = async () => {
    if (google) {
      const directionService = new google.maps.DirectionsService();
      if (ride.status == "onboard") {
        const resultat = await directionService.route({
          origin: new google.maps.LatLng({
            lat: ride.pickup.latitude,
            lng: ride.pickup.longitude,
          }),
          destination: new google.maps.LatLng({
            lat: ride.dropoff.latitude,
            lng: ride.dropoff.longitude,
          }),
          travelMode: google.maps.TravelMode.DRIVING,
        });

        setDirectionResponse(resultat);
        setDureTrajet(resultat.routes[0].legs[0].duration.value);
      } else if (ride.status == "onway" || ride.status == "waiting") {
        const resultat = await directionService.route({
          origin: new google.maps.LatLng({
            lat: coord.latitude,
            lng: coord.longitude,
          }),
          destination: new google.maps.LatLng({
            lat: ride.pickup.latitude,
            lng: ride.pickup.longitude,
          }),
          travelMode: google.maps.TravelMode.DRIVING,
        });
        setDirectionResponse(resultat);
        setDureTrajet(resultat.routes[0].legs[0].duration.value);
      } else if (ride.status == "pending") {
        const resultat = await directionService.route({
          origin: new google.maps.LatLng({
            lat: ride.pickup.latitude,
            lng: ride.pickup.longitude,
          }),
          destination: new google.maps.LatLng({
            lat: ride.dropoff.latitude,
            lng: ride.dropoff.longitude,
          }),
          travelMode: google.maps.TravelMode.DRIVING,
        });
        setDirectionResponse(resultat);
      }
    }
  };

  useEffect(() => {
    drawDirection();
  });
  useEffect(() => {
    dispatch(DureeTrajetActions.patientDureeTrajet(dureTrajet));
  }, [dureTrajet]);

  return isLoaded ? (
    <GoogleMap
      center={center}
      zoom={15}
      mapContainerStyle={containerStyle}
      options={googleMapStyle}
      onLoad={(map) => setMap(map)}
      onUnmount={onUnmount}
    >
      {ride.pickup && ride.status == "onway" ? (
        <Marker
          position={{
            lat: ride.pickup.latitude,
            lng: ride.pickup.longitude,
          }}
          icon={LieuDePriseEnCharge}
          cursor="pointer"
        />
      ) : (
        ""
      )}
      {coord && ride.status == "onway" ? (
        <Marker
          position={{
            lat: coord.latitude,
            lng: coord.longitude,
          }}
          icon={ImageVehicule}
          cursor="pointer"
        />
      ) : (
        ""
      )}
      {ride.pickup && ride.status == "waiting" ? (
        <Marker
          position={{
            lat: ride.pickup.latitude,
            lng: ride.pickup.longitude,
          }}
          icon={LieuDePriseEnCharge}
          cursor="pointer"
        />
      ) : (
        ""
      )}
      {coord && ride.status == "waiting" ? (
        <Marker
          position={{
            lat: coord.latitude,
            lng: coord.longitude,
          }}
          icon={ImageVehicule}
          cursor="pointer"
        />
      ) : (
        ""
      )}

      {ride.pickup && ride.status == "pending" ? (
        <Marker
          position={{
            lat: ride.pickup.latitude,
            lng: ride.pickup.longitude,
          }}
          icon={lieuPriseEnChargeMaps}
          cursor="pointer"
        />
      ) : (
        ""
      )}
      {ride.dropoff && ride.status == "pending" ? (
        <Marker
          position={{
            lat: ride.dropoff.latitude,
            lng: ride.dropoff.longitude,
          }}
          icon={lieuDariveMaps}
          cursor="pointer"
        />
      ) : null}

      {ride.pickup && ride.status == "onboard" ? (
        <Marker
          position={{
            lat: ride.pickup.latitude,
            lng: ride.pickup.longitude,
          }}
          icon={ImageVehicule}
          cursor="pointer"
        />
      ) : (
        ""
      )}
      {ride.dropoff && ride.status == "onboard" ? (
        <Marker
          position={{
            lat: ride.dropoff.latitude,
            lng: ride.dropoff.longitude,
          }}
          icon={LieuDarrivee}
          cursor="pointer"
        />
      ) : (
        ""
      )}

      {ride.status == "onway" ||
      ride.status == "onboard" ||
      ride.status == "pending" ||
      ride.status == "waiting"
        ? directionResponse && (
            <DirectionsRenderer
              directions={directionResponse}
              options={{
                markerOptions: { icon: " ", anchorPoint: { x: 0.2, y: 0.2 } },
                polylineOptions: {
                  zIndex: 10,
                  strokeColor: "#9273FF",
                  strokeWeight: 5,
                },
              }}
            />
          )
        : ""}
    </GoogleMap>
  ) : (
    <></>
  );
};

export default NewGoogleMap;
