import React, { useState } from "react";
import { Spacer, Modal, Button, Flex, Box, Divider, Text } from "native-base";
const Header = () => {
  const [showModal, setShowModal] = useState(false);

  return (
    <Box>
      <Button
        onPress={() => setShowModal(true)}
        bg="#9273FF"
        _hover={{
          backgroundColor: "#9273FF",
        }}
        _text={{
          color: "white",
          fontFamily: "Lato",
          fontWeight: "900",
          fontSize: "12px",
          textTransform: "uppercase",
        }}
        _focus={{
          backgroundColor: "#9273FF",
        }}
        marginTop="87px"
        marginBottom="28px"
        padding="10px, 15px, 10px, 15px"
        borderRadius="50px"
        width="305px"
        height="30px"
      >
        Téléchargez l’application patient resaam
      </Button>
      {/* add to the Modal boxe , user click on the button */}
      <Modal
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        _backdrop={{
          _dark: {
            bg: "white",
          },
          bg: "#C4C4C480",
        }}
      >
        <Modal.Content
          bg="white"
          maxWidth="350"
          maxH="212"
          marginTop="290px"
          marginBottom="85px"
        >
          <Modal.CloseButton />
          <Modal.Header
            alignItems="center"
            fontSize="17px"
            fontWeight="600"
            color="#000000"
            fontFamily="SF Pro Text"
          >
            Télécharger Resaam
          </Modal.Header>
          <Modal.Body letterSpacing="-0.08 px" alignItems="center">
            <Text
              color="#000000"
              fontFamily="SF Pro Text"
              fontWeight="400"
              Size="13px"
            >
              Facilitez vos prochains transports de
            </Text>
            <Text
              color="#000000"
              fontFamily="SF Pro Text"
              fontWeight="400"
              Size="13px"
            >
              santé avec l’application patient
            </Text>
            <Text
              color="#000000"
              fontFamily="SF Pro Text"
              fontWeight="400"
              Size="13px"
            >
              {" "}
              Resaam
            </Text>
          </Modal.Body>

          <Flex justifyContent="space-around" alignItems="center">
            <Divider orientation="horizontale" borderColor="gray.300" />
            <Modal.Footer bg={1}>
              <Button.Group space={2} fontFamily="SF Pro Text" fontSize="17px">
                <Button
                  _hover={{
                    backgroundColor: "white",
                  }}
                  // _text={{
                  //   color: "#007AFF",
                  // }}
                  colorScheme="none"
                  onPress={() => {
                    setShowModal(false);
                  }}
                >
                  <Text fontWeight="400" color="#007AFF" fontSize="17px">
                    Annuler
                  </Text>
                </Button>
                <Button
                  colorScheme="none"
                  _hover={{
                    backgroundColor: "white",
                  }}
                  // _text={{
                  //   color: "#007AFF",
                  // }}
                  onPress={() => {
                    setShowModal(false);
                  }}
                >
                  <Text
                    letterSpacing="-0.41px"
                    fontWeight="600"
                    color="#007AFF"
                    fontSize="17px"
                  >
                    Télécharger
                  </Text>
                </Button>
              </Button.Group>
            </Modal.Footer>
          </Flex>
        </Modal.Content>
      </Modal>
    </Box>
  );
};

export default Header;
